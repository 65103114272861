import wallets from '../data/walletsData.json'
import tables from '../data/tablesData.json'

export function getWallets() {
    return wallets
}

const BASE_URL = 'https://dev-engine.paygate.kz/local'

const checkResponse = (response) => (response.ok ? response.json() : Promise.reject(`Ошибка ${response.status}`))

export function getTableData(token) {
    // const username = 'user'
    // const password = 'W9~X?Gd{gXLn'

    // headers.append('Authorization', 'Basic ' + btoa(username + ':' + password))

    return fetch(`${BASE_URL}/report/agent/kcell/get-all`, {
        method: 'GET',

        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    }).then(checkResponse)
}

export function getBalance(token) {
    // const username = 'user'
    // const password = 'W9~X?Gd{gXLn'

    // headers.append('Authorization', 'Basic ' + btoa(username + ':' + password))

    return fetch(`${BASE_URL}/report/agent/kcell/balance`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    }).then(checkResponse)
}
